import React, { useState, useEffect } from 'react';
import { GET_MENU } from './../helpers/url_helper';
import { get } from '../helpers/api_helper';


// component
import Header from '../component/header';
import Footer from '../component/Footer';
import Loginsignupmodel from "./../component/auth/loginsignupmodel";
import LoginWith from "./../component/auth/LoginWith";
const MenuContext = React.createContext();

function Default({ children }) {

    window.scrollTo(0, 0);
    const [isLoader, setLoader] = useState(0);
    const [categories, SetCategories] = useState([]);
    const [menu, SetMenu] = useState([]);
    const [homeBanner, SetHomeBanner] = useState([]);
    const [minimum_person_save_card_details, Setminimum_person_save_card_details] = useState(0);

    useEffect(async () => {
        var res_data = await get(GET_MENU);
        if (res_data.status) {
            SetCategories(res_data.data.categories_list);
            SetMenu(res_data.data.menu);
            SetHomeBanner(res_data.data.banner_list);
            Setminimum_person_save_card_details(res_data.data.minimum_person_save_card_details);
        }
        setLoader(1);
    }, [])
    if (!isLoader) {
        return ('');
    }
    return (
        <>
            <div className="main_wrapper">
                <MenuContext.Provider value={{ categories: categories, menu: menu, homeBanner : homeBanner, minimum_person_save_card_details : minimum_person_save_card_details }}>
                    <Header />
                    {children}
                    <Footer />
                    <Loginsignupmodel />
                    <LoginWith />
                </MenuContext.Provider>
            </div>
        </>
    );
}

export default Default;
export { MenuContext }
