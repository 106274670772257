import { useEffect, useState, useRef } from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { GET_BANNER } from '../helpers/url_helper';
import { get } from '../helpers/api_helper';
import { img_view } from '../helpers/Helper';

export default function ImageCaro() {
    const [BannerList, setCategoryList] = useState([]);
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            var res_data = await get(GET_BANNER);
            if (res_data.status) {
                setCategoryList(res_data.data.banner_list);
            }
            setLoader(1);
        }
        get_detaile();
    }, [])

    if (!isLoader) {
        return ('Loading...');
    }
    return (<>
        <div className="image_slider_wrap">
            <div className="container">
                <OwlCarousel className='home_banner_slider owl-theme'
                    items={4}
                    loop={true}
                    margin={23}
                    autoplay={true}
                    dots={false}
                    nav={false}
                    mouseDrag={false}
                    touchDrag={false}
                    pullDrag={false}
                    freeDrag={false}
                    autoplayTimeout={2000}
                    autoplaySpeed={2000}
                    autoplayHoverPause={false}
                    responsive={{
                        0: {
                            items: 2
                        },
                        600: {
                            items: 3
                        },
                        1000: {
                            items: 3
                        },
                        1200: {
                            items: 4
                        }
                    }}
                >
                    {BannerList && Object.entries(BannerList).map(([key, value]) => {
                        return (<div className="item" key={key}>
                             <a href="https://www.instagram.com/ondamelbourne/" target="_blank"><div className="imagebox"><img src={img_view(value.image_name, 300, 300)} alt="" /></div></a>
                        </div>)
                    })}
                </OwlCarousel>
                <div className="insta_btn flxrow">
                    <a href="https://www.instagram.com/ondamelbourne/" target="_blank"><img src="/assets/images/instagram_icon.svg" alt="" /></a>
                </div>
            </div>
        </div>
    </>)
}
