import { useRef, useState } from "react";
import { post } from '../../helpers/api_helper';
import { POST_FORGOT_PASSWORD, POST_RESET_PASSWORD } from '../../helpers/url_helper';
import { toast } from 'react-toastify';
import { setUserSession } from '../../helpers/Helper';
import SimpleReactValidator from 'simple-react-validator';

export default function Footer() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const OtpValidator = useRef(new SimpleReactValidator());

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [otp_screen, SetOTP_screen] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '', c_password: '', otp: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_FORGOT_PASSWORD, { 'email': form_inputs.email }, {});
            if (res_data.status) {
                SetOTP_screen(true);
                toast(res_data.message);
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // form submit event
    const verifyOtp = async (event) => {
        event.preventDefault();
        const formValid = OtpValidator.current.allValid()
        if (!formValid) {
            OtpValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_RESET_PASSWORD, form_inputs, {});
            if (res_data.status) {
                setUserSession(res_data.data.token, res_data.data);
                document.getElementById('updateLogin').click();
                window.$("#ResetPassword").modal("hide");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (<>
        <div className="modal fade loginsignupmodel" id="ResetPassword" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="iconbox flxrow">
                        <div className="in_icon flxrow"><img src="/assets/images/login_popup_icon.svg" alt="" /></div>
                    </div>
                    <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body">
                        <div className="titlebox mt-5 mb-4"><h3>Reset Password</h3></div>
                        <div className="form_wrap">
                            {!otp_screen &&
                                <form onSubmit={handleSubmit}>
                                    <div className="form_row">
                                        <input type="text" name="email" value={form_inputs.email} onChange={handleInputChange} className="input_text" placeholder="Enter Email Address" />
                                        {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                    </div>
                                    <div className="form_row btnbox mb-5 mt-5">
                                        <button type="submit" disabled={ButtonDisabled} className="ondabtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>}  Reset</button>
                                    </div>
                                </form>
                            }
                            {otp_screen &&
                                <form onSubmit={verifyOtp}>

                                    <div className="form_row">
                                        <input type="text" name="otp" maxLength="6" value={form_inputs.otp} onChange={handleInputChange} className="input_text" placeholder="Enter OTP" />
                                        {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                    </div>
                                    <div className="form_row">
                                        <input type="password" name="password" value={form_inputs.password} onChange={handleInputChange} className="input_text" placeholder="Enter New Password" />
                                        {OtpValidator.current.message('password', form_inputs.password, 'required')}
                                    </div>
                                    <div className="form_row">
                                        <input type="password" name="c_password" value={form_inputs.c_password} onChange={handleInputChange} className="input_text" placeholder="Enter Confirm Password" />
                                        {OtpValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                    </div>
                                    <div className="form_row btnbox mb-5 mt-5">
                                        <button type="submit" disabled={ButtonDisabled} className="ondabtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>}  Change</button>
                                    </div>
                                </form>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
