import { useState, useEffect, useRef } from "react";
import { POST_GET_ORDER_HISTORY } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import { toast } from 'react-toastify';
import { Link, useHistory } from "react-router-dom";

import UserNameHeader from '../../component/UserNameHeader';
import UserMenu from '../../component/UserMenu';

export default function Home() {
    let history = useHistory();
    const [Page, setPage] = useState(0);
    const [OrderHistory, setOrderHistory] = useState({});
    const [ShowLoadMore, setShowLoadMore] = useState(false);

    useEffect(() => {
        async function get_detaile() {
            await get_order_history();
        }
        get_detaile();
    }, []);

    const get_order_history = async (load_more = 0) => {
        var page = (Page + load_more)
        setPage(page);
        var res_data = await post(POST_GET_ORDER_HISTORY, { page: page });
        if (res_data.status) {
            if (load_more) {
                setOrderHistory([...OrderHistory, ...res_data.data.order_list]);
            } else {
                setOrderHistory(res_data.data.order_list);
            }
            setShowLoadMore(res_data.data.load_more);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }
    const load_more = async () => {
		await get_order_history(1);
	}


    return (<>
        <UserNameHeader />
        <div class="container">
            <div class="order_his">
                <UserMenu />

                {OrderHistory.length > 0 && Object.entries(OrderHistory).map(([key, order]) => {
                    return (<div class="order_row" key={key}>
                        <ul>
                            <li><span><b>Order No: </b></span> <span><b>#{order.order_number} </b></span></li>
                            <li><span>Order Date: </span> <span>{order.order_date} </span></li>
                            <li><span>Pickp Date Time: </span> <span>{order.pickp_date}</span></li>
                            <li><span>Status: </span> 
                                {order.order_status === 'Pending' && <span class="btn_panding">{order.order_status}</span>}
                                {order.order_status === 'Confirmed' && <span class="btn_confirmed">{order.order_status}</span>}
                                {order.order_status === 'Ready For Pickup' && <span class="btn_rtpu">{order.order_status}</span>}
                                {order.order_status === 'Complete' && <span class="btn_complete">{order.order_status}</span>}
                                {order.order_status === 'Cancelled' && <span class="btn_canceld">{order.order_status}</span>}
                            </li>
                            <li><span><b>Total Amount:</b> </span> <span><b>${order.grand_total}</b></span></li>
                            <li><Link to={`/order-history/detail/${order.order_number}`}>View Details</Link></li>
                        </ul>
                    </div>)
                })}
                {OrderHistory.length <= 0 && <div className="text-center pb-5 pt-5">
                    <p>No any order</p>
                </div>}
                {ShowLoadMore && <div className="col-sm-12 text-center mt-5">
						<button type="button" onClick={load_more} className="btn btn-success btn-radius pl-3 pr-3 mr-2">Load More ...</button>
					</div>}
            </div>
        </div>

    </>)
}
