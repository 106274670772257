import { useState, useEffect } from "react";
import ImageCaro from './../../component/ImageCaro';
import ProductMenu from './../../component/ProductMenu';
import { GET_PRODUCT, POST_ADD_CARD } from '../../helpers/url_helper';
import { get, post } from '../../helpers/api_helper';
import { img_view, is_login,add_tocart } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import $ from 'jquery';



export default function Home(props) {
    const [product_list, Setproduct_list] = useState([]);

    useEffect(() => {
        async function get_detaile() {
            Setproduct_list([]);
            var res_data = await get(GET_PRODUCT + '?category=' + props.match.params.categori);
            Setproduct_list(res_data.data.product_list);
            if(props.location.hash){
                window.$('html, body').animate({
                    scrollTop: $(props.location.hash).offset().top
                }, 500);
            }
        }
        get_detaile();
    }, [props])

    const addToCard = async (product) => {
        var qty_type = 'Plates';
        if (product.qty_type !== 'Plates') {
            qty_type = product.select_qty;
        }
        
        // if (!is_login() && sessionStorage.getItem('GuestUser') != 'true') {
        //     window.$('#loginWith').modal('show');
        //     return false;
        // }

        add_tocart(product.product_id,0,qty_type,1);
        toast('Item added To cart');
        document.getElementById('updateLogin').click();
    }

    const setPrice = async (category_id, product_id, value) => {
        product_list[category_id].product[product_id].select_qty = value;
        Setproduct_list(inputs => ({ ...product_list }));
    }
    
    return (<>
        <div className="banner_wrapper">
            <div className="innerbanner">
                <div className="imagebox" style={{ 'backgroundImage': 'url(/assets/images/innerbanner.jpg)' }}></div>
                <div className="bann_captionbox">
                    <div className="container-fluid">
                        <h1>Order Online</h1>
                        <div className="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="maneupage_wrap">
            <div className="container">
                <ProductMenu />
                <div className="menurow_wrap">
                    {product_list && Object.entries(product_list).map(([key, categori]) => {
                        return (<>
                            <div className="menurowmain" key={key} id={categori.category_name.replaceAll(' ', '_')}>
                                <div className="menu_title flxrow">
                                    <h2>{categori.category_name}</h2>
                                </div>
                                <div className="menuboxwrap">
                                    <div className="row">
                                        {!categori.product.length && <div className="mt-5 mb-5"><p className="text-center">No any {categori.category_name}</p></div>}
                                        {categori.product && Object.entries(categori.product).map(([product_key, product]) => {
                                            return (<>
                                                <div className="col-xl-3 col-lg-4" key={product_key}>
                                                    <div className="menubox flxcol">
                                                        <div className="imagebox flxfix"><img src={img_view(product.product_image)} alt="" /></div>
                                                        <div className="detailbox flxflexi flxcol">
                                                            <h5>{product.product_name}</h5>
                                                            <p>{product.product_description}</p>

                                                            {product.qty_type === 'Plates' &&
                                                                <div className="priceqty flxrow">
                                                                    <div className="priceb">${product.price}</div>
                                                                    <div className="qtybox"></div>
                                                                </div>}

                                                            {product.qty_type !== 'Plates' &&
                                                                <div className="priceqty flxrow">
                                                                    <div className="priceb flxrow">
                                                                        {product.glass_price > 0 && <div className="pcheckb">
                                                                            <input type="radio" name={`glassbott_${product.product_id}`} value="Glass" checked={(product.select_qty === 'Glass') ? true : false} onChange={setPrice.bind(this, key, product_key, 'Glass')} id={`glassbott_${product.product_id}_glass`} />
                                                                            <label for={`glassbott_${product.product_id}_glass`}>
                                                                                <span>Glass <img src="images/price_check.svg" alt="" /></span>
                                                                                <strong>${product.glass_price}</strong>
                                                                            </label>
                                                                        </div>}
                                                                        {product.price > 0 && <div className="pcheckb">
                                                                            <input type="radio" name={`glassbott_${product.product_id}`} value="Bottle" checked={(product.select_qty === 'Bottle') ? true : false} onChange={setPrice.bind(this, key, product_key, 'Bottle')} id={`glassbott_${product.product_id}_bottle`} />
                                                                            <label for={`glassbott_${product.product_id}_bottle`}>
                                                                                <span>Bottle <img src="images/price_check.svg" alt="" /></span>
                                                                                <strong>${product.price}</strong>
                                                                            </label>
                                                                        </div>}
                                                                    </div>
                                                                    <div className="qtybox"></div>
                                                                </div>}
                                                            <div className="btnbox"><a href="javascript:void(0)" onClick={addToCard.bind(this, product)} className="ondabtn withborder lightpink">Add To Cart</a></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>)
                                        })}
                                    </div>
                                </div>
                            </div>
                        </>)
                    })}
                </div>
            </div>
        </div>
        <ImageCaro />
    </>)
}
