
export const GET_BANNER = "/com/get-banner"
export const GET_MENU = "/com/get-menu"
export const GET_VOUCHER = "/com/get-voucher"
export const GET_PRODUCT = "/com/get-products"
export const GET_PICKUP_TIME = "/com/get-pickup-time"
export const GET_TABLE_BOOKING_TIME = "/com/get-table-booking-time" 
export const GET_BOTTOMLESS_BRUNCH_TIME = "/com/get-bottomless-brunch-time" 
export const GET_FUNCTION_BOOKING_TIME = "/com/get-functions-booking-time" 


// auth
export const POST_REGISTER = "/auth/register"
export const POST_LOGIN = "/auth/login"

export const POST_CHANGE_PASSWORD = "/user/change-password"

export const POST_GET_ORDER_HISTORY = "/user/get-order-history"
export const POST_GET_TABLE_BOOKING_HISTORY = "/user/get-table-booking-history"
export const POST_GET_ORDER_DETAIL = "/com/get-order-detail"

export const POST_ORDER_PLACE = "/com/order-place"
export const POST_ORDER_CHECKOUT = "/com/checkout"
export const POST_ORDER_CHECKOUT_VOUCHER = "/com/checkout_voucher"
export const GET_CART = "/com/get-card"
export const POST_BOOK_TABLE = "/com/book-table"
export const GET_TABLE_BOOK_DETAIL = "/com/get-table-book-detail"
export const POST_CANCEL_BOOKING = "/com/cancel-booking"
export const POST_BOTTOMLESS_BRUNCH_BOOKING = "/com/book-bottomless-brunch" 
export const POST_FUNCTION_BOOKING = "/com/book-function" 
export const POST_CHECK_AVAILABLE_TABLE = "/com/check-available-table" 


export const POST_FORGOT_PASSWORD = "/auth/forgot-password"
export const POST_RESET_PASSWORD = "/auth/reset-password"
