
const getToken = () => {
    return sessionStorage.getItem('token') || null;
}

const getUserDetail = () => {
    return JSON.parse(sessionStorage.getItem('user_detail'));
}

const getUserId = () => {
    return (sessionStorage.getItem('user_detail')) ? JSON.parse(sessionStorage.getItem('user_detail'))['user_id'] : 0;
}

const getUserType = () => {
    if (sessionStorage.getItem('user_detail')) {
        return JSON.parse(sessionStorage.getItem('user_detail'))['u_type'];
    } else {
        return 0;
    }
}

const removeUserSession = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user_detail');
}

const setUserSession = (token, user) => {
    sessionStorage.setItem('token', token);
    sessionStorage.setItem('user_detail', JSON.stringify(user));
}

const is_login = () => {
    if (sessionStorage.getItem('token')) {
        return true;
    } else {
        return false;
    }
}

const amountFormat = (amount) => {
    return parseFloat(amount).toFixed(2);
}

const IsModuleAccess = (modem_name) => {
    return true;
    var mode = JSON.parse(JSON.parse(sessionStorage.getItem('user_detail'))['role_access']);
    if (mode[modem_name] && mode[modem_name].module_access === 1) {
        return true;
    } else {
        return false;
    }
}

const img_view = (img, width = '', height = '', auto = true) => {
    return process.env.REACT_APP_IMG_URL + 'uploads/' + img+'?time='+ new Date().getTime()
    if (auto) {
        return process.env.REACT_APP_IMG_URL + 'img?img=' + img + '&w=' + width + '&h=' + height+'&time='+ new Date().getTime()
    } else {
        return process.env.REACT_APP_IMG_URL + 'uploads/' + img+'?time='+ new Date().getTime()
    }
}

const add_tocart = (product_id, voucher_id, qty_type, qty) => {
    var cart_product = [];
    
    var cart = sessionStorage.getItem('cart');
    var cart_product_array = (cart) ? JSON.parse(cart) : [];
    var is_new_product = true;
    if (cart_product_array.length > 0) {
        for (var product of cart_product_array) {
            if(product.product_id == product_id && product.voucher_id == voucher_id && product.qty_type == qty_type){
                product.qty = (parseInt(product.qty) + parseInt(1));
                cart_product.push(product);
                is_new_product = false;
            }else{
                cart_product.push(product);
            }
        }

    }
    if(is_new_product){
        cart_product.push({
            product_id: product_id,
            voucher_id: voucher_id,
            qty_type: qty_type,
            qty: qty,
        });
    }
    sessionStorage.setItem('cart',JSON.stringify(cart_product));
    return cart_product;
}

const remove_cart_itam = (product_id, voucher_id) => {
    var cart_product = [];
    
    var cart = sessionStorage.getItem('cart');
    var cart_product_array = (cart) ? JSON.parse(cart) : [];
    if (cart_product_array.length > 0) {
        for (var product of cart_product_array) {
            if(product.product_id == product_id && product.voucher_id == voucher_id){
            }else{
                cart_product.push(product);  
            }
        }
    }
    sessionStorage.setItem('cart',JSON.stringify(cart_product));
    return cart_product;
}

const empty_cart = () => {
    sessionStorage.removeItem('cart');
}
export { empty_cart,remove_cart_itam,add_tocart,img_view, amountFormat, getUserType, getToken, removeUserSession, setUserSession, is_login, getUserDetail, getUserId, IsModuleAccess };