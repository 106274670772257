import { useState, useEffect, useContext } from "react";
import { is_login } from '../helpers/Helper';
import { POST_GET_ORDER_DETAIL } from '../helpers/url_helper';
import { post } from '../helpers/api_helper';
import { toast } from 'react-toastify';
import { Link,useHistory } from "react-router-dom";

export default function Home(props) {
    let history = useHistory();

    const [OrderDetail, setOrderDetail] = useState({});

    useEffect(async () => {
        var res_data = await post(POST_GET_ORDER_DETAIL, { order_no: props.match.params.order_no });
        if (res_data.status) {
            setOrderDetail(res_data.data.detail);
        } else {
            toast.error(res_data.message);
            history.push("/");
        }
    }, [])

    return (<>

        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{ "background-image": "url(/assets/images/function-booking-image.jpg)" }}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>Order Online</h1>
                        <div class="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="functionbooking_wrap">
            <div class="container">
                <div class="function_title">
                    <h2>Order Online</h2>
                </div>
                <div class="function_meta">
                    <div class="callaction"><img src="/assets/images/phone_icon.svg" alt="" />+61 3 9429 8589</div>
                    <div class="callaction"><img src="/assets/images/map_pin_icon_2.svg" alt="" />280 Bridge Rd, Richmond 3121</div>
                </div>
                <div class="function_form_wrap">
                    <div class="sucess_message">
                        <div class="online_ord_sucessmsg flxcol">
                            <strong>Order Submitted Successfully.</strong>
                            <p>A confirmation email was sent to <span>{OrderDetail.email}.</span></p>
                        </div>
                    </div>
                </div>


                <div class="order_num">
                    <p class="b_color">Order No: #{OrderDetail.order_number}</p>
                    <p><b>Total: ${OrderDetail.grand_total}</b></p>
                    {OrderDetail.order_type != 'Voucher' && <p>Pickup Time: {OrderDetail.pickp_date}</p>}
                    <p>Order Date: {OrderDetail.order_date}</p>
                </div>

                {is_login() && <Link to={`/order-history/detail/${OrderDetail.order_number}`} class="order_det_btc">Order Details</Link>}

            </div>
        </div>
    </>)
}
