import { useState, useEffect, useRef } from "react";
import { POST_GET_ORDER_DETAIL } from '../../helpers/url_helper';
import { post } from '../../helpers/api_helper';
import { img_view } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import { Link, useHistory } from "react-router-dom";

import UserNameHeader from '../../component/UserNameHeader';
import UserMenu from '../../component/UserMenu';

export default function Home(props) {
    let history = useHistory();
    const [OrderDetail, setOrderDetail] = useState({});
    const [isLoader, setLoader] = useState(0);

    useEffect(() => {
        async function get_detaile() {
            setLoader(0);
            var res_data = await post(POST_GET_ORDER_DETAIL, { order_no: props.match.params.order_no });
            if (res_data.status) {
                setOrderDetail(res_data.data.detail);
            } else {
                toast.error(res_data.message);
                history.push("/");
            }
            setLoader(1);
        }
        get_detaile();
    }, [props.match.params.order_no]);
    if (!isLoader) {
        return ('Loading...');
    }
    return (<>
        <UserNameHeader />
        <div class="container">
            <div class="order_his">
                <UserMenu />
                <div class="order_details_row">
                    <Link to="/order-history"><img src="/assets/images/back_order.svg" /></Link>
                    <span order_number><b>Order No: #{OrderDetail.order_number}</b></span>
                    {OrderDetail.order_status === 'Pending' && <span class="btn_panding">{OrderDetail.order_status}</span>}
                    {OrderDetail.order_status === 'Confirmed' && <span class="btn_confirmed">{OrderDetail.order_status}</span>}
                    {OrderDetail.order_status === 'Ready For Pickup' && <span class="btn_rtpu">{OrderDetail.order_status}</span>}
                    {OrderDetail.order_status === 'Complete' && <span class="btn_complete">{OrderDetail.order_status}</span>}
                    {OrderDetail.order_status === 'Cancelled' && <span class="btn_canceld">{OrderDetail.order_status}</span>}
                    
                    <span order_number><b>Payment Status : </b></span>
                    {OrderDetail.payment_status === 'Pending' && <span class="btn_panding">{OrderDetail.payment_status}</span>}
                    {OrderDetail.payment_status === 'Complete' && <span class="btn_complete">{OrderDetail.payment_status}</span>}
                    {(OrderDetail.payment_status === 'Failed' || OrderDetail.payment_status === 'Cancelled' ) && <span class="btn_canceld">{OrderDetail.payment_status}</span>}
                    <span class="order_right"><b>Total: ${OrderDetail.grand_total}</b></span>
                    <span class="order_right">Pickup Time: {OrderDetail.pickp_date}</span>
                    <span class="order_right">Order Date: {OrderDetail.order_date}</span>
                </div>

                <div class="order_dtl_list">
                    <ul class="row_gry">
                        <li><b>Image</b></li>
                        <li><b>Items</b></li>
                        <li><b>Qty</b></li>
                        <li><b>Price</b></li>
                    </ul>

                    {OrderDetail && Object.entries(OrderDetail.order_detail).map(([key, order]) => {
                        return (<ul>
                            <li>
                                <img src={img_view(order.image, 100, 100)} />
                            </li>
                            <li>
                                <span><b>{order.product_name} {order.title}</b></span>
                                <span>{order.product_description}</span>
                                <b> {order.qty_type}</b>
                            </li>
                            <li>{order.qty}</li>
                            <li>${order.total_amount}</li>
                        </ul>)
                    })}
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <b>Note.</b>
                        <p>{OrderDetail.order_note}</p>
                    </div>
                    <div className="col-sm-6">
                        <div class="order_sub_total">
                            <ul>
                                <li>Sub Total: </li>
                                <li></li>
                                <li>${OrderDetail.sub_total}</li>
                            </ul>
                            {OrderDetail.tax_amount > 0 && <ul>
                                <li>GST ({OrderDetail.tax_pre}%) inc. in price: </li>
                                <li></li>
                                <li>${OrderDetail.tax_amount}</li>
                            </ul>}
                            <ul>
                                <li>Order Tip: </li>
                                <li></li>
                                <li>${OrderDetail.tip}</li>
                            </ul>
                            <ul>
                                <li>Promo Code:</li>
                                <li> {OrderDetail.promo_code}</li>
                                <li>-${OrderDetail.discount}</li>
                            </ul>
                            <ul class="bottom_bor">
                                <li><b>Total:</b></li>
                                <li></li>
                                <li><b>${OrderDetail.payable_amount}</b></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}
