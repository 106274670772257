export default function Home(props) {

    return (<>
        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{"background-image": "url(/assets/images/innerbanner.jpg)"}}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>About Us</h1>
                        <div class="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="ourstory_wrap">
            <div class="container">
                <div class="storybox">
                    <div class="titlebox flxrow"><h2>ONDA Story</h2></div>
                    <p>We are your new special place. A place made of Latin American flavours, coupled with the warmth of home. <br />Onda is Spanish for vibes – and the feeling that we hope to spread is of good energy through our soulful meals, and familiar hospitality. Dine with us at Onda Bar & Eatery. It’s all bueno here.</p>
                </div>
            </div>
        </div>

        <div class="functionbook_sec">
            <div class="container">
                <div class="row flex-row-reverse">
                    <div class="col-lg-6">
                        <div class="imagebox"><img src="/assets/images/Home_Page_function.jpg" alt="" /></div>
                    </div>
                    <div class="col-lg-6">
                        <div class="captoinbox">
                            <h3>About Onda Restaurant</h3>
                            {/* <h6>Lorem Ipsum Text Here... Make it a Bouzy night to remember</h6> */}
                            <p className="pt-4">Let us celebrate your special moments ONDA. We can create a package based on your requirements. Our menu is Gluten Free, It can be Onion free, garlic free and suitable for vegan/ vegetarian/ pascatarian</p>
                            <p className="pt-4">accommodates up to 60 guests and is available to book exclusively for lunch or dinner with a minimum spend of $2,500. Reservations can be made up to three months in advance.</p>
                            <p className="pt-4">For larger groups, exclusive hire our full restaurant.</p>
                            <p className="pt-4">For further information and to enquire about availability, please complete the form below and we will be in touch soon.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
