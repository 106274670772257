export default function Home(props) {

    return (<>
        <div class="banner_wrapper">
            <div class="innerbanner">
                <div class="imagebox" style={{"background-image": "url(/assets/images/innerbanner.jpg)"}}></div>
                <div class="bann_captionbox">
                    <div class="container-fluid">
                        <h1>Contact Us</h1>
                        <div class="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="functionbook_sec contact_us">
            <div class="container">
                <div class="row flex-row-reverse">


                    <div class="col-lg-6">
                        <div class="imagebox con_bg_img">
                            <div class="img_con_text">
                                <h5>Work With Us</h5>
                                <p>We highly value our staff and are always looking for passionate chefs and front of house staff.</p>
                                <p> If you’re interested in learning about Argentinian cuisine then email (info@ondamelbourne.com) your CV now to  join our talented and exciting team.</p>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="captoinbox">
                            <div class="address_info">
                                <div class="con_icon"><img src="/assets/images/contact_address_icon.svg" alt="" /></div>
                                <div class="con_text">
                                    <h5>Address</h5>
                                    <p>ONDA</p>
                                    <p>280 Bridge road,  <br />Richmond, VIC-3121</p>
                                </div>
                            </div>

                            <div class="address_info con_phone">
                                <div class="con_icon"><img src="/assets/images/contact_phone_icon.svg" alt="" /></div>
                                <div class="con_text">
                                    <h5>Phone</h5>
                                    <p>+61 3 9429 8589</p>
                                </div>
                            </div>


                            <div class="address_info">
                                <div class="con_icon"><img src="/assets/images/contact_email_icon.svg" alt="" /></div>
                                <div class="con_text">
                                    <h5>Email</h5>
                                    <p>info@ondamelbourne.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="map_ife">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.77139957364!2d144.99822831488268!3d-37.81882317975139!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad6435419de81c9%3A0xa0de1611d45c9780!2sONDA%20Bar%20%26%20Eatery!5e0!3m2!1sen!2sin!4v1673606784700!5m2!1sen!2sin" width="100%" height="450" style={{ "border": "0" }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>
        </div>

    </>)
}
