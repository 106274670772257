import { useRef, useState } from "react";
import { Link, NavLink, } from "react-router-dom";
import { post } from '../helpers/api_helper';
import { POST_CHANGE_PASSWORD } from '../helpers/url_helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

export default function Footer() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ old_password: '', password: '', c_password: '' });
    const [, forceUpdate] = useState();

    // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_CHANGE_PASSWORD, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                window.$("#ChangePassword").modal("hide");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    return (<>
        <div class="order_his_btn">
            <ul>
                <li><NavLink to="/order-history" exact>Order History</NavLink></li>
                <li><NavLink to="/bottomless-brunch-history" exact>Bottomless Brunch</NavLink></li>
                <li><NavLink to="/table-booking-history" exact>Table History</NavLink></li>
                <li><NavLink to="/functions-booking-history" exact>Functions </NavLink></li>
                <li><Link to="#" data-bs-toggle="modal" data-bs-target="#ChangePassword" class="change_pas">Change Password</Link></li>
            </ul>
        </div>

        <div className="modal fade loginsignupmodel" id="ChangePassword" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="iconbox flxrow">
                        <div className="in_icon flxrow"><img src="/assets/images/login_popup_icon.svg" alt="" /></div>
                    </div>
                    <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body">
                        <div className="titlebox mt-5 mb-4"><h3>Change Password</h3></div>
                        <div className="form_wrap">
                            <form action="" onSubmit={handleSubmit}>
                                <div className="form_row">
                                    <input type="password" name="old_password" onChange={handleInputChange} value={form_inputs.old_password} className="input_text" placeholder="Enter Old Password" />
                                    {simpleValidator.current.message('old password', form_inputs.old_password, 'required')}
                                </div>
                                <div className="form_row">
                                    <input type="password" name="password" onChange={handleInputChange} value={form_inputs.password} className="input_text" placeholder="Enter New Password" />
                                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                                </div>
                                <div className="form_row">
                                    <input type="password" name="c_password" onChange={handleInputChange} value={form_inputs.c_password} className="input_text" placeholder="Confirm Password" />
                                    {form_inputs.password && simpleValidator.current.message('confirm password', form_inputs.c_password, `required|in:${form_inputs.password}`, { messages: { in: `Passwords don't match.` } })}
                                </div>
                                <div className="form_row btnbox mb-5 mt-5">
                                    <button type="submit" disabled={ButtonDisabled} className="ondabtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>}  Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
