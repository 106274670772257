
export default function Model() {
    return (<>
        <div className="modal fade loginsignupmodel" id="loginWith" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body pb-4">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="titlebox mt-2 mb-3"><h3>Login with</h3></div>
                                <p className="text-center mb-3">IT'S ALL BUENO</p>
                            </div>
                            <div className="col-sm-12 text-center">
                                <button type="button" className="btn btn-lightpink mr-10" data-bs-dismiss="modal" onClick={() => {sessionStorage.setItem('GuestUser',true)}} > Guest</button>
                                <button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => { window.$('#loginsignup').modal('show');}}> Login</button>
                            </div>
                            <div className="col-sm-6">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
