import { getUserDetail } from '../helpers/Helper';

export default function Header() {

    return (
        <>
            <div class="banner_wrapper">
                <div class="plain_banner">
                    <div class="container">
                        <h2>{getUserDetail().user_name}</h2>
                        <p>{getUserDetail().email}</p>
                    </div>
                </div>
            </div>
        </>
    )
}
