
import { useRef, useState } from "react";
import {post} from '../../helpers/api_helper';
import { POST_LOGIN } from '../../helpers/url_helper';
import { setUserSession } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

export default function Model() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [form_inputs, setInputs] = useState({ email: '', password: '' });
    const [, forceUpdate] = useState();

      // form submit event
    const handleSubmit = async (event) => {
        event.preventDefault();

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);
            var res_data = await post(POST_LOGIN, form_inputs, {});
            if (res_data.status) {
                toast(res_data.message);
                setUserSession(res_data.data.token, res_data.data);
                document.getElementById('updateLogin').click();
                window.$("#loginsignup").modal("hide");
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }


    return (<>

        <div className="titlebox"><h3>User Login</h3></div>
        <div className="form_wrap">
            <form action="" onSubmit={handleSubmit}>
                <div className="form_row">
                    <input type="text" name="email" onChange={handleInputChange} value={form_inputs.email} className="input_text" placeholder="Your Email Id:" />
                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                </div>
                <div className="form_row">
                    <input type="password" name="password" onChange={handleInputChange} value={form_inputs.password} className="input_text" placeholder="Password:" />
                    {simpleValidator.current.message('password', form_inputs.password, 'required')}
                </div>
                <div className="form_row btnbox">
                    <button type="submit" disabled={ButtonDisabled} className="ondabtn greenbtn" >{ButtonDisabled && <i className="spinner"></i>} Login</button>
                </div>
            </form>
            <div className="forgetpassrow">Forgotten your password? <a href="#" onClick={() => {window.$("#loginsignup").modal("hide");}} data-dismiss="modal" data-bs-toggle="modal" data-bs-target="#ResetPassword">Reset password</a></div>
        </div>
        <div className="saveorder">Login or create an account to save all your orders</div>
    </>)
}
