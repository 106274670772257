import Login from "./Login";
import Register from "./Register";
import ResetPassword from "./ResetPassword";

export default function Model() {
    return (<>

        <div className="modal fade loginsignupmodel" id="loginsignup" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <div className="iconbox flxrow">
                        <div className="in_icon flxrow"><img src="/assets/images/login_popup_icon.svg" alt="" /></div>
                    </div>
                    <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body">
                        <div className="tab_nav_login flxrow" >
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="logintab-tab" data-bs-toggle="tab" data-bs-target="#logintab" type="button" role="tab" aria-controls="logintab" aria-selected="true">Login</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="registertab-tab" data-bs-toggle="tab" data-bs-target="#registertab" type="button" role="tab" aria-controls="registertab" aria-selected="false">Register</button>
                                </li>
                            </ul>
                        </div>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane logintab_body fade show active" id="logintab" role="tabpanel" aria-labelledby="logintab-tab">
                                <Login />
                            </div>
                            <div className="tab-pane registertab_body fade" id="registertab" role="tabpanel" aria-labelledby="registertab-tab">
                                <Register />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ResetPassword/>
    </>)
}
