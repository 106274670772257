import { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";

import ImageCaro from './../../component/ImageCaro';
import ProductMenu from './../../component/ProductMenu';
import { GET_VOUCHER, POST_ORDER_CHECKOUT_VOUCHER } from '../../helpers/url_helper';
import { get, post } from '../../helpers/api_helper';
import { img_view, is_login, add_tocart } from '../../helpers/Helper';
import { toast } from 'react-toastify';
import SimpleReactValidator from 'simple-react-validator';

import StripeCheckout from 'react-stripe-checkout';


export async function getServerSideProps({ req, query }) {
    var res_data = await get(GET_VOUCHER);
    return {
        props: {
            voucher: res_data.data.voucher_list
        }
    }
}

export default function Home(props) {
    let history = useHistory();

    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [voucher, setVoucher] = useState([]);
    const [form_inputs, setInputs] = useState({ voucher_id: 0, email: '', full_name: '', phone_number: '', price: 0, name: "Onda Bar & Eatery", descroption: ''});
    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();

    useEffect(() => {
        async function get_detaile() {
            var res_data = await get(GET_VOUCHER);
            setVoucher(res_data.data.voucher_list);
        }
        get_detaile();
    }, [props])

    const buy = async (voucher) => {
        SetButtonDisabled(false);
        window.$('#UsedDetail').modal('show');
        setInputs({
            voucher_id: voucher.voucher_id,
            email: '',
            full_name: '',
            phone_number: '',
            price: voucher.amount
        })
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            window.$('.StripeCheckout').click();
            SetButtonDisabled(true);
        }
    }

    const handleToken = async (token, address) => {
        SetButtonDisabled(true);

        // form_inputs.product = product
        form_inputs.token = token

        const response = await post(POST_ORDER_CHECKOUT_VOUCHER, form_inputs);
        if (response.status) {
            window.$('#UsedDetail').modal('hide');
            history.push("/order-success/" + response.data.order_no);
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    return (<>
        <div className="banner_wrapper">
            <div className="innerbanner">
                <div className="imagebox" style={{ 'backgroundImage': 'url(/assets/images/innerbanner.jpg)' }}></div>
                <div className="bann_captionbox">
                    <div className="container-fluid">
                        <h1>Order Online</h1>
                        <div className="opentiming">100% Gluten Free</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="maneupage_wrap">
            <div className="container">
                <ProductMenu />
                <div className="gift_voucher_wrap">
                    <div className="row">
                        {voucher && Object.entries(voucher).map(([key, voucher]) => {
                            return (<><div className="col-lg-3" key={key}>
                                <div className="gifvoucherbox flxcol">
                                    <div className="imagebox flxfix">
                                        <img src={img_view(voucher.image, 300, 300)} alt="" />
                                        <div className="disbox"><span>${voucher.amount}</span> Onda  Gift Voucher</div>
                                    </div>
                                    <div className="detailbox flxflexi flxcol">
                                        <h5>{voucher.title}</h5>
                                        <p>{voucher.description}</p>
                                        <div className="btnbox"><a href="javascript:void(0)" onClick={buy.bind(this, voucher)} className="ondabtn withborder lightpink">Buy</a></div>
                                    </div>
                                </div>
                            </div></>)
                        })}
                    </div>
                </div>
            </div>
        </div>
        <ImageCaro />

        <div className="modal fade loginsignupmodel" id="UsedDetail" >
            <div className="modal-dialog modal-dialog-centered ">
                <div className="modal-content">
                    <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                        <img src="/assets/images/popup_close_icon.svg" alt="" />
                    </button>
                    <div className="modal-body pb-4 pt-5">
                        <form action="" onSubmit={handleSubmit}>
                            <div className="row">
                                <div className="col-sm-12">
                                    <div className="form_wrap">
                                        <div className="form_row">
                                            <div className="labeltext">Full Name</div>
                                            <div className="inputwrap">
                                                <input type="text" className="input_text" name="full_name" onChange={handleInputChange} value={form_inputs.full_name} placeholder="Enter Your Full Name" />
                                                {simpleValidator.current.message('full name', form_inputs.full_name, 'required')}
                                            </div>
                                        </div>
                                        <div className="form_row">
                                            <div className="labeltext">Email</div>
                                            <div className="inputwrap">
                                                <input type="text" className="input_text" name="email" onChange={handleInputChange} value={form_inputs.email} placeholder="Enter Your Email Address" />
                                                {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                            </div>
                                        </div>
                                        <div className="form_row mb-4">
                                            <div className="labeltext">Phone Number</div>
                                            <div className="inputwrap">
                                                <input type="text" className="input_text" name="phone_number" maxLength={12} onChange={handleInputChange} value={form_inputs.phone_number} placeholder="Enter Your Phone Number" />
                                                {simpleValidator.current.message('phone number', form_inputs.phone_number, 'required|phone')}
                                            </div>
                                        </div>
                                        <div className="col-sm-12 text-center">
                                            <button type="submit" disabled={ButtonDisabled} className="ondabtn greenbtn">{ButtonDisabled && <i className="spinner"></i>} Proceed To Pay ${form_inputs.price}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

        <StripeCheckout
            className="btn btn-success StripeCheckout none"
            stripeKey={process.env.REACT_APP_STRIPE_KEY}
            token={handleToken}
            email={form_inputs.email}
            currency={process.env.REACT_APP_CURRENCY}
            amount={form_inputs.price * 100}
            name={form_inputs.name}
            billingAddress={false}
            alipay={false}
        />
    </>)
}
