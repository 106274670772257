import { useState, useRef,useContext } from "react";
import { NavLink,useHistory } from "react-router-dom";
import { MenuContext } from '../layouts/Default';

export default function Footer() {
    const history = useHistory();

    const {categories} = useContext(MenuContext);

    return (<>
        <div className="menu_tabnav flxrow">
            <ul>
                {categories && Object.entries(categories).map(([key, menu]) => {
                    return (<>
                        <li key={key}><NavLink to={`/menu/${menu.category_name.toLowerCase()}`}>{menu.category_name}</NavLink></li>
                    </>)
                })}
                <li><NavLink to="/gift-voucher" className={`giftvoucher ${history.pathname == "/gift-voucher" ? "active" : ""}`} >Gift Vouchers</NavLink></li>
            </ul>
            <div className="btnbox">
                <a href="javascript:void(0);" onClick={() => { document.getElementById('time_model').click(); }} className="ondabtn" >
                    <img src="/assets/images/pickup_icon.svg" alt="" />Pickup Only</a>
                {/* <div className="pickedtime">
                    {PickupTime.date && <span>{PickupTime.date} - {PickupTime.time} <img src="/assets/images/pickedtime_icon.svg" alt="" /></span>}
                </div> */}
            </div>
        </div>
    </>)
}
