import { useState, useRef, useEffect, useContext } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import { is_login, removeUserSession, getUserDetail } from '../helpers/Helper';
import { GET_CART, POST_ORDER_CHECKOUT, POST_ORDER_PLACE, GET_PICKUP_TIME } from '../helpers/url_helper';
import { post, get } from '../helpers/api_helper';
import { remove_cart_itam, empty_cart } from '../helpers/Helper';
import { toast } from 'react-toastify';
import { MenuContext } from '../layouts/Default';
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import { addDays } from 'date-fns';

import SimpleReactValidator from 'simple-react-validator';

import StripeCheckout from 'react-stripe-checkout';


export default function Header({ children }) {
    let history = useHistory();


    const [showMobileMenu, SetShowMobileMenu] = useState(false);
    const [IsLogin, SetIsLogin] = useState(false);
    const { categories } = useContext(MenuContext)

    const [CartData, setCartData] = useState(0);
    const [Total, setTotal] = useState('0.00');
    const [form_inputs, setInputs] = useState({
        tip: '',
        promo_code: '',
        note: '',
        date: '',
        time: '',
        email: '',
        full_name: '',
        phone_number: ''
    });
    const [Payment_Option, setPayment_Option] = useState({ name: "Onda Bar & Eatery", price: 0, descroption: '' });
    const [ButtonDisabled, SetButtonDisabled] = useState(false);
    const [OrderOnlineLink, SetOrderOnlineLink] = useState('/');

    const simpleValidator = useRef(new SimpleReactValidator());
    const [, forceUpdate] = useState();
    const [startDate, setStartDate] = useState('');

    useEffect(async () => {
        check_login();
        if (categories.length > 0) {
            SetOrderOnlineLink('/menu/' + categories[0].category_name.toLowerCase());
        }
        form_inputs.email = (getUserDetail()) ? getUserDetail().email : '';
        form_inputs.full_name = (getUserDetail()) ? getUserDetail().user_name : '';
        setInputs(form_inputs);
    }, [])

    const check_login = async () => {
        SetIsLogin(false);
        if (is_login()) {
            SetIsLogin(true);
        }
        await get_cart();
    }

    const Logout = async () => {
        await removeUserSession();
        await check_login();
        history.push("/");
    }

    const get_cart = async () => {
        var product = (sessionStorage.getItem('cart'));
        product = (product) ? JSON.parse(product) : [];
        var res_data = await post(GET_CART, { product: product, tip: form_inputs.tip, promo_code: form_inputs.promo_code });
        if (res_data.status) {
            setCartData(res_data.data);
            setTotal(res_data.data.total_amount);
            if (res_data.message) {
                toast.error(res_data.message)
            }
        } else {
            setCartData({});
            setTotal('0.00');
        }
    }

    const remove = async (product_id, voucher_id) => {
        remove_cart_itam(product_id, voucher_id)
        get_cart();
    }

    // input text change handler
    const handleInputChange = (event) => {
        event.persist();
        if (event.target.name === 'tip') {
            form_inputs.tip = event.target.value.replace(/[^0-9]/g, '')
            setInputs(form_inputs);
            get_cart();
        } else if (event.target.name == 'date') {
            for (var date_value of dateList) {
                if (date_value.date == event.target.value) {
                    setTimeList(date_value.time);
                }
            }
            form_inputs.time = '';
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        } else {
            setInputs(inputs => ({ ...form_inputs, [event.target.name]: event.target.value }));
        }
    }

    const plase_order = async (event) => {
        event.preventDefault();


        if (!form_inputs.date || !form_inputs.time) {
            document.getElementById('time_model').click();
            SetButtonDisabled(false);
            return false
        }

        const formValid = simpleValidator.current.allValid()
        if (!formValid) {
            simpleValidator.current.showMessages(true);
            forceUpdate(1);
        } else {
            SetButtonDisabled(true);

            var product = (sessionStorage.getItem('cart'));
            form_inputs.product = (product) ? JSON.parse(product) : [];

            var res_data = await post(POST_ORDER_PLACE, form_inputs, {});
            if (res_data.status) {

                if (res_data.data.order_no > 0) {
                    form_inputs.tip = 0;
                    form_inputs.promo_code = '';
                    setInputs(form_inputs);
                    empty_cart();
                    window.$('body').removeClass('cartshow');
                    get_cart();
                    history.push("/order-success/" + res_data.data.order_no);
                } else {
                    setPayment_Option({ 'price': res_data.data.payable_amount });
                    window.$('.StripeCheckout').click()
                }
            } else {
                toast.error(res_data.message);
            }
            SetButtonDisabled(false);
        }
    }

    const addTip = async (pre) => {
        var tip = Math.round((parseFloat(CartData.sub_total) * pre) / 100);
        form_inputs.tip = tip
        setInputs(form_inputs);
        get_cart();
    }

    const handleToken = async (token, address) => {
        SetButtonDisabled(true);
        var product = (sessionStorage.getItem('cart'));
        product = (product) ? JSON.parse(product) : [];

        form_inputs.product = product
        form_inputs.token = token

        const response = await post(POST_ORDER_CHECKOUT, form_inputs);
        if (response.status) {
            form_inputs.tip = 0;
            form_inputs.promo_code = '';
            setInputs(form_inputs);
            empty_cart();
            window.$('body').removeClass('cartshow');
            get_cart();
            history.push("/order-success/" + response.data.order_no);
        } else {
            toast.error(response.message);
        }
        SetButtonDisabled(false);
    }

    const [dateList, setDateList] = useState([]);
    const [timeList, setTimeList] = useState([]);

    const get_time = async () => {
        var res_data = await get(GET_PICKUP_TIME);
        if (res_data.status) {
            setDateList(res_data.data.date_array);
            if (setInputs.data) {
                for (var date_value of res_data.data.date_array) {
                    if (date_value.date == setInputs.date) {
                        setTimeList(date_value.time);
                    }
                }
            }
        }
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!form_inputs.date || !form_inputs.time) {
            toast.error('Please select date and time')
            return false;
        }
        window.$('#pickupmodel').modal('hide');
    }

    const setDate = (event) => {
        setStartDate(event);
        var date = new Date(event),
            mnth = ("0" + (date.getMonth() + 1)).slice(-2),
            day = ("0" + date.getDate()).slice(-2);
        var new_date = [date.getFullYear(), mnth, day].join("-");

        setTimeList([]);
        for (var date_value of dateList) {
            if (date_value.date == new_date) {
                setTimeList(date_value.time);
            }
        }
        form_inputs.time = "";
        form_inputs.date = new_date;
        setInputs(inputs => ({ ...form_inputs, ['date']: new_date }));
        console.log(form_inputs);
    }

    return (
        <>
            <div className="header_wrapper">
                <div className="container">
                    <div className="header_main flxrow">
                        <div className="logo">
                            <Link to="/"><img src="/assets/images/logo.svg" alt="" /></Link>
                        </div>
                        <div className="navigation flxrow">
                            <ul className={`nav-bar ${showMobileMenu ? 'show_mobile_menu' : ''}`} onClick={() => { SetShowMobileMenu(false)}}>
                                <div class="bx bx-x"></div>
                                <li><NavLink to="/" exact>Home</NavLink></li>
                                <li className="has_submenu">
                                    <a href="#">Menu</a>
                                    <ul>
                                        {categories && Object.entries(categories).map(([key, menu]) => {
                                            return (<>
                                                <li className={`${menu.sub_categories.length > 0 ? "submenu1" : ""}`} key={key}>
                                                    <Link to={`/menu/${menu.category_name.toLowerCase()}`}>{menu.category_name}</Link>
                                                    {menu.sub_categories.length > 0 &&
                                                        <ul className="none">
                                                            {Object.entries(menu.sub_categories).map(([key, sub_menu]) => {
                                                                return (<>
                                                                    <li key={key}><Link to={`/menu/${menu.category_name.toLowerCase()}#${sub_menu.category_name.replaceAll(' ', '_').toLowerCase()}`}>{sub_menu.category_name}</Link></li>
                                                                </>)
                                                            })}
                                                        </ul>
                                                    }
                                                </li>
                                            </>)
                                        })}
                                        <li><Link to="/gift-voucher">Gift Vouchers</Link></li>
                                    </ul>
                                </li>
                                <li><NavLink to="/function-booking" className={`nav-item ${history.pathname == "/function-booking" ? "active" : ""}`}>Functions</NavLink></li>
                                <li><NavLink to={OrderOnlineLink}>Order Online</NavLink></li>
                                <li><NavLink to="/table-booking">Book a Table</NavLink></li>
                            </ul>
                            <div className="btnbox">
                                <button id="updateLogin" className="none" type="button" onClick={check_login}></button>
                                {!IsLogin && <div className="inbtn">
                                    <a href="#" className="loginbtn" id="loginbtn" data-bs-toggle="modal" data-bs-target="#loginsignup">
                                        <span><img src="/assets/images/login_icon.svg" alt="" /></span>Login
                                    </a>
                                </div>}
                                {IsLogin &&
                                    <div className="inbtn dropdown">
                                        <a href="javascript:void(0);" className="loginbtn haslogged" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" >
                                            <span><img src="/assets/images/login_icon.svg" alt="" /></span>{getUserDetail().user_name}
                                        </a>
                                        <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                            <li><Link className="dropdown-item" to="/order-history">Order History</Link></li>
                                            <li><Link className="dropdown-item" to="/bottomless-brunch-history">Bottomless Brunch History</Link></li>
                                            <li><Link className="dropdown-item" to="/table-booking-history">Table History</Link></li>
                                            <li><Link className="dropdown-item" to="/functions-booking-history">Functions History</Link></li>
                                            {/* <li><a className="dropdown-item" href="#">Change Password</a></li> */}
                                            <li><a className="dropdown-item" href="javascript:void(0);" onClick={Logout}>Logout</a></li>
                                        </ul>
                                    </div>
                                }
                                <div className="inbtn">
                                    <div className="backdropbox"><a href="javascript:void(0);" onClick={() => { window.$('body').removeClass('cartshow'); }}></a></div>
                                    <a href="javascript:void(0);" onClick={() => { get_cart(); window.$('body').toggleClass('cartshow'); }} className="cartbtn hascarditems" >
                                        <span><img src="/assets/images/cart_icon.svg" alt="" /></span>${Total}
                                    </a>
                                    <div className="cart_dropdown">
                                        <div className="cart_title">
                                            <h3>Cart</h3>
                                        </div>
                                        {CartData.product_list?.length > 0 && <>
                                            <div className="cartlistwrap">
                                                <div className="headingtr flxrow">
                                                    <div className="headth col1 flxfix">Qty</div>
                                                    <div className="headth col2 flxflexi">Item</div>
                                                    <div className="headth col3 flxfix">Price</div>
                                                </div>
                                                {Object.entries(CartData.product_list).map(([product_key, product]) => {
                                                    return (<div className="bodytr flxrow" key={product_key}>
                                                        <div className="bodytd col1 flxfix">
                                                            <div className="qtybox">{product.qty}</div>
                                                        </div>
                                                        <div className="bodytd col2 flxflexi">
                                                            <div className="pname">{product.title}</div>
                                                            {/* <div className="psubtext">{product.qty_type}</div> */}
                                                            <div className="materialsbox">{product.qty_type}</div>
                                                        </div>
                                                        <div className="bodytd col3 flxfix flxcol">
                                                            <div className="pricebox">${product.amount}</div>
                                                            <div className="removebtn"><a href="javascript:void(0);" onClick={remove.bind(this, product.product_id, product.voucher_id)}>Remove</a></div>
                                                        </div>
                                                    </div>);
                                                })}
                                            </div>
                                            <div className="subtotalbox">
                                                <div className="insubrow subtotal flxrow">
                                                    <div className="labelbox flxflexi">Sub Total</div>
                                                    <div className="amountbox flxfix">${CartData.sub_total}</div>
                                                </div>
                                                <div className="insubrow gstrow flxrow">
                                                    <div className="labelbox flxflexi">GST ({CartData.tax_pre}%) inc. in price</div>
                                                    <div className="amountbox flxfix none">${CartData.tax_amount}</div>
                                                </div>
                                                <div className="insubrow gstrow flxrow">
                                                    <div className="labelbox flxflexi">Order Tip</div>
                                                    <div className="amountbox flxfix"> ${CartData.tip}</div>
                                                </div>
                                                <div className="insubrow gstrow flxrow">
                                                    <div className="labelbox flxflexi green_txt">Gift Voucher</div>
                                                    <div className="amountbox flxfix green_txt">- ${CartData.discount} </div>
                                                </div>
                                                <div className="insubrow totalrow flxrow">
                                                    <div className="labelbox flxflexi">Total</div>
                                                    <div className="amountbox flxfix">${CartData.total_amount}</div>
                                                </div>
                                            </div>
                                            <div className="billtipwrap flxrow">
                                                <div className="currencybox flxrow flxfix"><img src="/assets/images/doller_icon.svg" alt="" /></div>
                                                <div className="tipinput"><input type="text" name="tip" onChange={handleInputChange} value={form_inputs.tip} placeholder="Add an order tip" /></div>
                                                <div className="persantagebox flxfix"><button type="button" onClick={addTip.bind(this, 10)} id="tip10">10%</button></div>
                                                <div className="persantagebox flxfix"><button type="button" onClick={addTip.bind(this, 15)} id="tip15">15%</button></div>
                                                <div className="persantagebox flxfix"><button type="button" onClick={addTip.bind(this, 20)} id="tip20">20%</button></div>
                                            </div>
                                            <div className="promocodebox">
                                                <div className="labeltext">Promo Code</div>
                                                <div className="inputwrap">
                                                    <input type="text" className="promo_inp" name="promo_code" onChange={handleInputChange} value={form_inputs.promo_code} placeholder="Enter Your Promo Code" />
                                                    <a href="javascript:void(0);" onClick={get_cart} class="app_btc">Apply</a>
                                                </div>
                                            </div>
                                            <div className="specialnote flxrow">
                                                <div className="iconbox flxfix flxrow"><img src="/assets/images/special_note_icon.svg" alt="" /></div>
                                                <div className="inputwrap flxflexi">
                                                    <input type="text" name="note" onChange={handleInputChange} value={form_inputs.note} placeholder="Special instructions…" />
                                                </div>
                                            </div>
                                            <div className="promocodebox pb-0">
                                                <div className="labeltext">Full Name</div>
                                                <div className="inputwrap">
                                                    <input type="text" name="full_name" onChange={handleInputChange} value={form_inputs.full_name} placeholder="Enter Your Full Name" />
                                                    {simpleValidator.current.message('full name', form_inputs.full_name, 'required')}
                                                </div>
                                            </div>
                                            <div className="promocodebox pb-0">
                                                <div className="labeltext">Email</div>
                                                <div className="inputwrap">
                                                    <input type="text" name="email" onChange={handleInputChange} value={form_inputs.email} placeholder="Enter Your Email Address" />
                                                    {simpleValidator.current.message('email', form_inputs.email, 'required|email')}
                                                </div>
                                            </div>
                                            <div className="promocodebox">
                                                <div className="labeltext">Phone Number</div>
                                                <div className="inputwrap">
                                                    <input type="text" name="phone_number" maxLength={12} onChange={handleInputChange} value={form_inputs.phone_number} placeholder="Enter Your Phone Number" />
                                                    {simpleValidator.current.message('phone number', form_inputs.phone_number, 'required|phone')}
                                                </div>
                                            </div>

                                            <div className="specialnote time_box flxrow" onClick={() => { document.getElementById('time_model').click(); }}>
                                                <div className="inputwrap flxflexi">
                                                    <b>Pickup Time: </b>  {form_inputs.date && form_inputs.date} {!form_inputs.date && 'YYY-MM-DD'} {form_inputs.time && form_inputs.time} {!form_inputs.time && 'HH:MM'}
                                                </div>
                                                <div className="iconbox flxfix flxrow"><img src="/assets/images/pickup_icon.svg" alt="" /></div>
                                            </div>
                                            <div className="btnbox"><button type="button" onClick={plase_order} disabled={ButtonDisabled} className="ondabtn withborder greenbtn full-width Checkoutbtn"> {ButtonDisabled && <i className="spinner"></i>} Proceed To Checkout</button></div>
                                        </>}
                                        {!CartData.product_list?.length && <>
                                            <h5 className="text-center mt-5 mb-0">No items in cart</h5>
                                            <p className="text-center  mb-5">Add some first to proceed</p>
                                        </>}
                                    </div>
                                </div>
                                <div className="bx bx-menu" onClick={() => { SetShowMobileMenu(true)}}>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </div>
                            </div>
                        </div>
                        <StripeCheckout
                            className="btn btn-success StripeCheckout none"
                            stripeKey={process.env.REACT_APP_STRIPE_KEY}
                            token={handleToken}
                            email={form_inputs.email}
                            currency={process.env.REACT_APP_CURRENCY}
                            amount={Payment_Option.price * 100}
                            name={Payment_Option.name}
                            billingAddress={false}
                            alipay={false}
                        />
                    </div>
                </div>
            </div>

            <button type="button" onClick={() => { get_time() }} id="time_model" className="none" data-bs-toggle="modal" data-bs-target="#pickupmodel"></button>

            <div className="modal fade pickup_planpopup" id="pickupmodel" tabIndex="-1" aria-labelledby="pickupmodelLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered ">
                    <div className="modal-content">
                        <div className="model_header">
                            <h3>Choose A Pickup Time</h3>
                            <button type="button" className="btn-close flxrow" data-bs-dismiss="modal" aria-label="Close">
                                <img src="/assets/images/close_icon2.svg" alt="" />
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="pickupbody">
                                <div className="titlebox">
                                    <h4>When Would You Like Your Order</h4>
                                    {/* <span>Pickup currently closed but you can order for later</span> */}
                                </div>
                                <div className="formbox">
                                    <form action="" onSubmit={handleSubmit}>
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="input_box">
                                                    <label htmlFor="">Select Date</label>
                                                    <DatePicker
                                                        dateFormat="yyyy-MM-dd"
                                                        selected={startDate}
                                                        minDate={new Date()}
                                                        maxDate={addDays(new Date(), 6)}
                                                        onChange={setDate}
                                                        className="form-control"
                                                        placeholderText="Select Date"
                                                    />
                                                    {/* <select className="selectcus form-control" value={setInputs.date} onChange={handleInputChange} name="date">
                                                        <option value="">Select Date</option>
                                                        {dateList && Object.entries(dateList).map(([key, date]) => {
                                                            return (<option value={date.date} key={key}>{date.date}</option>);
                                                        })}
                                                    </select> */}
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div className="input_box">
                                                    <label htmlFor="">End Date</label>
                                                    <select className="selectcus form-control" value={setInputs.time} onChange={handleInputChange} name="time">
                                                        <option value="">Select Time</option>
                                                        {timeList && Object.entries(timeList).map(([key, date]) => {
                                                            return (<option value={date.time} key={key}>{date.time}</option>);
                                                        })}
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="btnbox"><input type="submit" value="Select" className="ondabtn greenbtn" /></div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
